import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { ErrorBoundaryContext } from "@redash/viz/lib/components/ErrorBoundary";
import { Auth, clientConfig } from "@/services/auth";

// This wrapper modifies `route.render` function and instead of passing `currentRoute` passes an object
// that contains:
// - `currentRoute.routeParams`
// - `onError` field which is a `handleError` method of nearest error boundary

function NoSessionWrapper({ currentRoute, renderChildren }) {
  const { handleError } = useContext(ErrorBoundaryContext);
  return (
    <React.Fragment key={currentRoute.key}>
      {renderChildren({ ...currentRoute.routeParams, pageTitle: currentRoute.title, onError: handleError})}
    </React.Fragment>
  );
}

NoSessionWrapper.propTypes = {
  renderChildren: PropTypes.func,
};

NoSessionWrapper.defaultProps = {
  renderChildren: () => null,
};

export default function routeWithoutAnything({ render, ...rest }) {
  return {
    ...rest,
    render: currentRoute => (
      <NoSessionWrapper currentRoute={currentRoute} renderChildren={render} />
    ),
  };
}
